import React from "react";

import { Avatar, Badge, createStyles, makeStyles, Theme, Tooltip, Typography, useTheme, Link as MLink } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';

import { Link } from "react-router-dom";

import User from "./User";
import { formatDate } from "../Util";
import { useTranslation } from "react-i18next";
import { MaxAllowedWarnings } from "../Config";
import UserType, { userTypeToStr } from "./UserType";
import SteamID from "steamid";
import { UnregisteredUser } from "../server/ServerRecord";
import { UserTemp } from "../map/MapDataDTO";

export interface AuthorLinkProps {
  author: User | UserTemp | UnregisteredUser | string,
  steam_id: string,
  avatar?: boolean,
  date?: Date,
  small?: boolean,
  dontClampName?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noUpperCase: {
      textTransform: "none",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1)
    },
    link: {
      color: "white",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      marginRight: theme.spacing(1)
    },
    smallAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

const isAuthor = (val: User | UserTemp | UnregisteredUser | string): val is User => {
  const user = (val as User);

  return user.steamID !== undefined && user.type !== undefined;
};

const isUserTemp = (val: string | UserTemp | UnregisteredUser): val is UserTemp => {
  return (val as UserTemp).steam_id !== undefined;
};

const isUnregisteredUser = (val: User | UnregisteredUser | string): val is UnregisteredUser => {
  return (val as UnregisteredUser).displayName !== undefined;
};

const clampName = (name: string, dontClampName: boolean | undefined) => {
  if (dontClampName) {
    return name;
  }

  const MaxPlayerNameLength = 22;

  if (name.length > MaxPlayerNameLength) {
    return name.substring(0, MaxPlayerNameLength) + "...";
  }

  return name;
};

export default function AuthorLink(props: AuthorLinkProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  if (isAuthor(props.author) || isUserTemp(props.author)) {
    const avatar = <Avatar
      alt="avatar"
      src={props.author.avatar.large}
      className={classes.avatar}
      style={{
        width: props.small ? theme.spacing(3) : undefined,
        height: props.small ? theme.spacing(3) : undefined,
      }}
    />;

    const warningsToColor = (warnings: number) => {
      if (warnings > MaxAllowedWarnings) {
        return "red";
      }

      if (warnings === MaxAllowedWarnings) {
        return "tomato";
      }

      if (warnings === 1) {
        return "yellow";
      }

      return "darkorange";
    };

    const userTypeToColor = (type: string) => {
      switch (type) {
        case UserType[UserType.Root]: return "red";
        case UserType[UserType.Admin]: return "aqua";
        case UserType[UserType.Mapper]: return "#ff6200";
        case UserType[UserType.VIP]: return "#00b8ff";
        case UserType[UserType.ServerAdmin]: return "lightgreen";

        default: return "currentColor";
      }
    };

    let steamId = "";
    let type = UserType[UserType.Normal];
    let displayName = "";

    if (isUserTemp(props.author)) {
      steamId = props.author.steam_id;
      type = props.author.user_type;
      displayName = props.author.display_name;
    } else {
      steamId = props.author.steamID;
      type = UserType[props.author.type];
      displayName = props.author.displayName;
    }

    return <div className={classes.link}>
      <Link
        to={`/profile/${steamId}`}
        className={classes.link}
        style={{
          color: userTypeToColor(type)
        }}
      >
        {props.avatar &&
          (
            props.author.warnings !== undefined && props.author.warnings !== 0
              ? <Badge
                overlap="rectangular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={
                  <Tooltip title={`${t(props.author.warnings <= MaxAllowedWarnings ? "warn" : "banned", { count: props.author.warnings })}`}>
                    <WarningIcon
                      fontSize={props.small ? "small" : "default"}
                      style={{
                        color: warningsToColor(props.author.warnings)
                      }}
                    />
                  </Tooltip>
                }
              >
                {avatar}
              </Badge>
              : avatar
          )}

        <Tooltip title={type}>
          <div>
            {clampName(displayName, props.dontClampName)} {type === UserType[UserType.VIP] && <sup>VIP</sup>}
          </div>
        </Tooltip>
      </Link>

      {props.date &&
        <Typography variant="overline" className={classes.noUpperCase} color="textSecondary">
          {formatDate(props.date)}
        </Typography>
      }
    </div>;
  } else if (isUnregisteredUser(props.author)) {
    return <div className={classes.link}>
      <MLink
        href={(props.steam_id !== "STEAM_ID_LAN" && props.steam_id !== "BOT") ? `http://steamcommunity.com/profiles/${new SteamID(props.steam_id).getSteamID64()}` : "#"}
        target="_blank"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center"
          }}
        >
          <Avatar
            alt="avatar"
            src={props.author.avatar.large}
            className={classes.avatar}
            style={{
              width: props.small ? theme.spacing(3) : undefined,
              height: props.small ? theme.spacing(3) : undefined,
            }}
          />

          {clampName(props.author.displayName, props.dontClampName)}
        </div>
      </MLink>
    </div>;
  } else {
    return <div className={classes.link}>
      <MLink
        href={(props.steam_id !== "STEAM_ID_LAN" && props.steam_id !== "BOT" && !props.steam_id.startsWith("VALVE")) ? `http://steamcommunity.com/profiles/${new SteamID(props.steam_id).getSteamID64()}` : "#"}
        target="_blank"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center"
          }}
        >
          <Avatar>
            <Typography
              style={{
                position: "relative",
                top: "3px"
              }}
            >
              RJ
            </Typography>
          </Avatar>

          {props.author}
        </div>
      </MLink>
    </div>;
  }
};
